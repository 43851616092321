import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { Spacer, LinkBase, Addr } from '../components/atoms'

import { TextBase as Text } from '../components/blocks'

import {
  Box,
  Flex,
  Link,
  Input,
  Label,
  Button,
  Textarea,
  Radio,
  Container,
} from 'theme-ui'

/**
 * Team Page
 * @param {*} members
 */

const ContactPage = () => (
  <Layout>
    <SEO
      title={'Contact '}
      description="Get in touch with our team to get started with your queries"
    />
    <Container my={4} mb={6}>
      <Spacer>
        <Box sx={{ marginBottom: '1.5rem' }}>
          <Text variant="text.pageTitle" mb={3}>
            Hire us to help you build excellent software
          </Text>
        </Box>
        <Box
          as="form"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          action="/talk"
          sx={{ width: '40%' }}
        >
          <Input type="hidden" name="bot-field" />
          <Box sx={{ marginBottom: '1.5rem'}}>
            <Label htmlFor="company">
              What is your Company or Project name?{' '}
            </Label>
            <Input name="company" />
          </Box>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Label htmlFor="name">What is your name ? </Label>
            <Input name="name" />
          </Box>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Label htmlFor="email">What is your email address?</Label>
            <Input type="email" name="email" />
          </Box>

          <Box sx={{ marginBottom: '1.5rem' }}>
            <Label htmlFor="budget">What is your budget?</Label>
            <Flex mb={3}>
              <Label width={1}>
                <Radio name="budget" value="24" /> Less than £25,000
              </Label>
              <Label>
                <Radio name="budget" value="25_50" /> £25,000 - £50,000
              </Label>
              <Label>
                <Radio name="budget" value="50_100" /> £50,000 - £100,000
              </Label>
              <Label>
                <Radio name="budget" value="100_" /> £100,000 or more
              </Label>              
            </Flex>
          </Box>

          <Box sx={{ marginBottom: '1.5rem' }}>
            <Label htmlFor="comment">Comment</Label>
            <Textarea name="comment" id="comment" rows="6" mb={3} />
          </Box>
          <Button>Submit</Button>
        </Box>
      </Spacer>
      <Spacer>
        <Box pt={3} width={4 / 12}>
          <Addr>
            Functionary Labs Pvt Ltd, #24, Caravel Building, 1st A Main Road, ST
            Bed, Koramangala 4th Block, Bengaluru, Karnataka 560095
          </Addr>
        </Box>
      </Spacer>
    </Container>
  </Layout>
)

export default ContactPage
